import React from 'react';
import { Button, Dropdown, Icon, Label, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { API, graphqlOperation } from 'aws-amplify';
import Database from '../../database/Database';
import { Notification, NotificationsProps, TradeNotations } from '../../types';
import * as subscriptions from '../../graphql/subscriptions';
import { getHumanReadableDate } from '../../utils/calculations/timeCalculation';
import {
  OnCreateNotificationSubscription,
  OnUpdateNotificationSubscription,
} from '../../API';
import { millisecToSec } from '../../backtest-node/utils';
import useAuth from '../hooks/useAuth';

const DB = new Database();

type NotificationProps = {};

const getNumofNewNotification = (notifications?: NotificationsProps) =>
  notifications
    ? Object.entries(notifications).reduce(
        (acc, notification) => (!notification[1].seen ? acc + 1 : acc),
        0
      )
    : 0;
const trigger = (notifications?: NotificationsProps) => {
  const newNotifications = getNumofNewNotification(notifications);

  return (
    <>
      <Icon
        name={newNotifications ? 'bell' : 'bell slash'}
        color={newNotifications ? 'red' : 'grey'}
      />
      {newNotifications > 0 && (
        <Label circular as="span" size="medium">
          {newNotifications}
        </Label>
      )}
    </>
  );
};

type NotificationItemDescProps = {
  notificationID: string;
  coinID: string | undefined;
  notation: TradeNotations;
  date: number;
  isAdmin: boolean;
};

const NotificationItemDesc: React.FC<NotificationItemDescProps> = ({
  notificationID,
  coinID,
  notation,
  date,
  isAdmin,
}) => {
  const secPassedFromNotification = millisecToSec(new Date().getTime() - date);
  const now = DateTime.local();

  return (
    <div className="notification-items" key={notificationID}>
      {isAdmin && (
        <Button
          icon
          basic
          style={{
            padding: 0,
            boxShadow: 'none',
          }}
          onClick={() => {
            console.log(notificationID);
            DB.markNotificationAsRead(notificationID);
          }}
        >
          <Icon name="envelope open outline" />
        </Button>
      )}
      {coinID && coinID !== '' && (
        <Label color="yellow" active>
          <Link target="_blank" to={`/awscoin/${coinID}/${notation}`}>
            {coinID?.split('-')[0].toUpperCase()}
          </Link>
        </Label>
      )}
      <Label>{notation.toUpperCase()}</Label>
      <Label
        style={{
          background: 'none',
          opacity: 0.5,
          margin: 0,
          float: 'right',
        }}
      >
        <Icon name="calendar alternate outline" />
        {now
          .minus({ second: secPassedFromNotification })
          .toRelative({ style: 'short' })}
        <em style={{ opacity: 0.7 }}> {getHumanReadableDate(date)}</em>
      </Label>
    </div>
  );
};

const getNotificationItems = (
  notification: [string, Notification],
  isAdmin: boolean
) => {
  const { id, message, type, coinID, date, notation }: Notification =
    notification[1];
  const color =
    type === 'success' ? 'green' : type === 'warning' ? 'orange' : 'red';

  return (
    <Dropdown.Item
      key={id}
      style={{
        whiteSpace: 'unset',
        width: '360px',
      }}
      description={
        <NotificationItemDesc
          notificationID={id}
          coinID={coinID}
          notation={notation ?? 'btc'}
          date={date}
          isAdmin={isAdmin}
        />
      }
      text={
        <Message
          color={color}
          content={message}
          style={{ boxShadow: 'none' }}
        />
      }
    />
  );
};

type NotificationAWSResponse = {
  value: { data: OnCreateNotificationSubscription };
};

const addNewItemToNotification = (
  response: NotificationAWSResponse,
  notifications: NotificationsProps | undefined,
  setNotifications: React.Dispatch<
    React.SetStateAction<NotificationsProps | undefined>
  >
) => {
  const newNotification = {
    ...response.value.data.onCreateNotification,
    seen: response.value.data.onCreateNotification!.seen ?? false,
  };

  if (notifications) {
    const updatedNotifications = {
      ...notifications,
      [Object.keys(notifications).length]: newNotification,
    };

    setNotifications(updatedNotifications);
  }
};

type UpdateNotificationResp = {
  value: {
    data: {
      onUpdateNotification: OnUpdateNotificationSubscription['onUpdateNotification'];
    };
  };
};

const removeItemFromNotification = (
  response: UpdateNotificationResp,
  notifications: NotificationsProps | undefined,
  setNotifications: React.Dispatch<
    React.SetStateAction<NotificationsProps | undefined>
  >
) => {
  if (!notifications) return;
  const { id, seen } = response.value.data.onUpdateNotification!;
  const updated = Object.entries(notifications).reduce(
    (acc, notification, idx) => {
      if (notification[1].id === id && seen) return acc;

      return { ...acc, [idx]: notification[1] };
    },
    {}
  );

  setNotifications(updated);
};

const Notifications: React.FC<NotificationProps> = () => {
  const [notifications, setNotifications] =
    React.useState<NotificationsProps>();
  const { isAdmin } = useAuth({ useAuto: false });

  const saveNotificationsToState = async () => {
    const allNotifications = await DB.getAllNotifications();
    setNotifications(allNotifications.items);
  };

  React.useEffect(() => {
    saveNotificationsToState();
  }, []);

  React.useEffect(() => {
    const createSub = (
      API.graphql(graphqlOperation(subscriptions.onCreateNotification)) as any
    ).subscribe({
      next: (response: NotificationAWSResponse) =>
        addNewItemToNotification(response, notifications, setNotifications),
      error: (error: string) => console.warn(error),
    });

    return () => createSub.unsubscribe();
  });

  React.useEffect(() => {
    const updateSub = (
      API.graphql(graphqlOperation(subscriptions.onUpdateNotification)) as any
    ).subscribe({
      next: () =>
        // removeItemFromNotification(response, notifications, setNotifications)
        saveNotificationsToState(),
      error: (error: string) => console.warn(error),
    });
    // updateSub();

    return () => updateSub.unsubscribe();
  });

  const numOfNewNotifications = notifications
    ? getNumofNewNotification(notifications)
    : 0;

  return (
    <div>
      <Dropdown
        direction="left"
        trigger={trigger(notifications)}
        icon={numOfNewNotifications && 'dropdown'}
        labeled
        className="icon"
      >
        {numOfNewNotifications ? (
          <Dropdown.Menu>
            {isAdmin && (
              <>
                <Dropdown.Header
                  icon="envelope open"
                  content="Mark all Notification as Read"
                  style={{
                    opacity: '.5',
                    cursor: 'pointer',
                    textAlign: 'right',
                    background: 'white',
                  }}
                  onClick={async (): Promise<void | null> => {
                    if (!notifications) return null;

                    await DB.markAllNotificationAsRead(notifications);
                    return null;
                  }}
                />
                <Dropdown.Divider />
              </>
            )}

            {notifications &&
              Object.entries(notifications)
                .filter((notification) => !notification[1].seen)
                .sort((a, b) => b[1].date - a[1].date)
                .map((notification) =>
                  getNotificationItems(notification, isAdmin)
                )}
          </Dropdown.Menu>
        ) : (
          <></>
        )}
      </Dropdown>
    </div>
  );
};

export default Notifications;
